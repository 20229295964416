import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { Box, Flex, Spinner } from 'theme-ui'
import Album from './Album'
import filterAlbums from '../../helpers/filterAlbums'
import GallerySwitcher from './GallerySwitcher'
import findAlbumsByIndex from '../../helpers/findAlbumIndex'
import LoaderSpinner from '../UI/LoaderSpinner'

export default function Gallery() {
  const removeAlbums = [
    'Entrees',
    'Cocktails',
    'Salads',
    'Sides',
    'Appetizers',
    'Sides',
    'Pastas',
    'Pizzas',
    'Sandwiches',
    'Pizza',
    'Website Photos',
    'Shouts',
    'Dessert Menu',
  ]
  const defaultAlbumOpen = ''

  const gonationID = 'bzn-ZwpxdubGQEuQvv1q88omCw'

  const [galleryData, setGalleryData] = useState({
    albums: null,
    isLoading: true,
    albumOpenIndex: 0,
  })

  // fetch data from gonation and sets it to component state.
  const fetchData = async () => {
    await axios({
      url: `https://data.prod.gonation.com/profile/gallery?profile_id=${gonationID}`,
      adapter: jsonpAdapter,
    }).then((res) => {
      // checks for albums you want to remove if any else use all albums.
      const albumList = removeAlbums
        ? filterAlbums(res.data, removeAlbums)
        : res.data

      setGalleryData({
        ...galleryData,
        albums: albumList, // data is stored here
        isLoading: false, // once found data set loading off
        albumOpenIndex: defaultAlbumOpen
          ? findAlbumsByIndex(albumList, defaultAlbumOpen) // if there is a default opening set in the config use that one by an index find
          : 0, // if there is only one album move into that album
      })
    })
  }

  useEffect(() => {
    fetchData()
    return () => {}
  }, [])

  return (
    <Flex sx={styles.container}>
      {!galleryData.isLoading ? (
        <>
          {galleryData ? (
            <Box sx={styles.wrapper}>
              <GallerySwitcher
                galleryData={galleryData}
                setGalleryData={setGalleryData}
              />
              <Album galleryData={galleryData} />
            </Box>
          ) : (
            ''
          )}
        </>
      ) : (
        <Box sx={styles.spinner}>
          <LoaderSpinner />
        </Box>
      )}
    </Flex>
  )
}

const styles = {
  padding: '2rem 0.5rem',
  width: '100%',
  '.lazyload-wrapper': {
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },

  spinner: {
    width: '100%',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
