import React from 'react'
import { Flex, Heading } from 'theme-ui'

export default function GalleryAlbums({ galleryData, setGalleryData }) {
  return (
    <Flex sx={styles.albumsContainer} className='albumsContainer'>
      {galleryData.albums.map((album, index) => {
        const { _id, name } = album
        return (
          <Flex
            sx={`${
              galleryData.albumOpenIndex === index
                ? styles.albumsCTAActive
                : styles.albumsCTA
            }`}
            className={
              galleryData.albumOpenIndex === index
                ? 'albumsCTAActive'
                : 'albumsCTA'
            }
            key={_id}
            onClick={() =>
              setGalleryData({ ...galleryData, albumOpenIndex: index })
            }
          >
            <Heading as='h4' sx={styles.albumTitle} className='albumTitle'>
              {name}
            </Heading>
          </Flex>
        )
      })}
    </Flex>
  )
}

const styles = {
  // ?============================
  // ?====  Albums Containers ====
  // ?============================

  albumsContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0rem',
    marginBottom: '2rem',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'primary',
  },

  albumsCTA: {
    margin: '0.5rem',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: ['1', '', 'unset'],
    textAlign: 'center',
  },
  albumsCTAActive: {
    margin: '0.5rem',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: ['1', '', 'unset'],
    textAlign: 'center',
    opacity: '0.7',
    textDecoration: 'underline',
  },

  albumTitle: {
    variant: 'buttons.primary',
    width: '100%',
    // fontSize: ['1rem', '1rem', '1.5rem'],
    margin: '0.5rem',
  },
}
