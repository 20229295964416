import React, { useState } from 'react'
import { Box, Image, Flex, Heading } from 'theme-ui'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import Lightbox from 'react-image-lightbox'
import LazyLoader from '../GeneralComponents/LazyLoader'
import slugify from '../../helpers/slugifyLower'
import 'react-image-lightbox/style.css'

export default function Album({ galleryData, backToAlbumView }) {
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: '',
    photoIndex: 0,
  })

  const albumData =
    galleryData?.albums?.[
      galleryData.albumOpenIndex >= 0 ? galleryData.albumOpenIndex : 0
    ]

  const photosList = albumData?.photos

  return (
    <Box
      sx={styles.albumContainer}
      className={`albumContainer ${slugify(albumData.name)}`}
    >
      <Heading sx={styles.albumName} className='albumName'>
        {albumData.name}
      </Heading>
      <LazyLoader>
        <Flex sx={styles.albumPhotos} className='albumPhotos'>
          {photosList.map((photo, index) => (
            <Image
              className='albumImage'
              onClick={() => {
                setLightbox({
                  isOpen: true,
                  photoIndex: index,
                })
              }}
              sx={styles.albumImage}
              key={photo.imageUrl}
              src={`${photo.imageUrl}`}
              alt='Album_photo'
            />
          ))}
        </Flex>
      </LazyLoader>

      {lightBox.isOpen && (
        <Lightbox
          mainSrc={photosList[lightBox.photoIndex].imageUrl}
          nextSrc={
            photosList[(lightBox.photoIndex + 1) % photosList.length].imageUrl
          }
          onMoveNextRequest={() =>
            setLightbox({
              isOpen: true,
              photoIndex: (lightBox.photoIndex + 1) % photosList.length,
            })
          }
          prevSrc={
            photosList[
              (lightBox.photoIndex + photosList.length - 1) % photosList.length
            ].imageUrl
          }
          onMovePrevRequest={() => {
            setLightbox({
              isOpen: true,
              photoIndex: (lightBox.photoIndex + 1) % photosList.length,
            })
          }}
          onCloseRequest={() => setLightbox({ ...lightBox, isOpen: false })}
          imageCaption={photosList[lightBox.photoIndex].caption}
        />
      )}
    </Box>
  )
}

const styles = {
  // ?============================
  // ?=====  Album Container =====
  // ?============================
  albumContainer: {
    display: 'flex',
    '.lazyload-wrapper': {
      width: '100%',
    },
  },

  albumName: {
    display: 'flex',
    width: 'auto',
    writingMode: 'vertical-lr', //* writting mode changes the width property in unexpected ways
    marginRight: ['1.5rem', '', '2rem', '3rem'], //* this has to match the fontsize to get the Right height
    paddingTop: '1rem',
    opacity: '0.6',
    textTransform: 'uppercase',
    fontSize: ['1.5rem', '', '2rem', '3rem'], //* this has to match the MarginRight to get the Right height
  },

  albumPhotos: {
    flexWrap: 'wrap',
    alignItems: 'stretch',
    margin: '0rem auto 1rem',
    justifyContent: 'center',
    gridGap: 4,
    gridTemplateColumns: 'repeat( auto-fill, minmax( 250px, 1fr ) )',
    gridAutoRows: '100px',
    padding: '0rem 0.5rem',
    display: 'grid !important',
  },
  albumImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: 1,
    gridRowEnd: 'span 2',
    ':nth-child(4n+1)': {
      gridRowEnd: 'span 3',
    },
  },
}
