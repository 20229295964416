import React from 'react'
import { Box, Text, Heading } from 'theme-ui'
import PageHero from '../components/Heros/PageHero'
import Gallery from '../components/Gallery/Gallery'

export default function gallery() {
  return (
    <Box sx={styles.container}>
      <PageHero imageID={'gallery-pagehero'} />
      <Gallery />
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
  },
}
