import React from 'react'
import { Box } from 'theme-ui'
import LazyLoad from 'react-lazyload'
import LoaderSpinner from '../UI/LoaderSpinner'

export default function LazyLoader({ children }) {
  return (
    <LazyLoad
      placeholder={
        <Box sx={styles.spinner}>
          <LoaderSpinner />
        </Box>
      }
    >
      {children}
    </LazyLoad>
  )
}

const styles = {
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },
}
